/* Tänne sellasia tyylejä, joita käytetään useissa komponenteissa */

.display-title {
  font-family: 'Mochiy Pop One', 'Gill Sans', 'Gill Sans MT', Calibri,
    'Trebuchet MS', sans-serif;
  letter-spacing: 4px;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 2px;
}

.db-bg {
  background-color: #9bd4ac;
}

.nav-bg {
  background-color: #2c8c7d

  ;
}

