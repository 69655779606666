/* Etusivun alotusscreenin tyylit 
- ehkä vähän innostuin ton ravun kaa :D t.Veera*/

.heroText {
  text-align: center;
  color: #146555;
  background-color: #d7c3f1;
  height: 85vh;
  min-height: 400px;
  width: 100vw;
  padding: 50px 0px 0px 0px;
}

#otacruise{
  width: 90vw;
  max-width: 800px;
  padding: 0 0 10px 0;
}

#year{
  width: 40vw;
  max-width: 400px;
  padding: 0 0 15px 0;
}

.titleNbutton {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
}

h1 {
  margin-bottom: 40px;
}

.date {
  font-size: 32px;
  padding-bottom: 50px !important;
}

.motto{
  padding: 0 50px;
}

.ticketsAction {
  margin: 10px;
  font-size: 20px;
  color: #9bd4ac;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: #d7c3f1;
  border-color: #9bd4ac;
  border-radius: 10px;
  align-self: stretch;
}

.ticketsAction:hover {
  background-color: #d7c3f1;
  color: #9bd4ac;
  border-color: #9bd4ac;
}

a.ticketLinkHero{
  padding: 10px 20px;
  color: #9bd4ac;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

a.ticketLinkHero:hover{
  color: #9bd4ac;
  border-color: #10295f;
}

.callToAction {
  font-size: 20px;
  color: #166555;
  border-color: #166555;
  border-radius: 10px;
  border-width: 2px;
  align-self: stretch;
}

.callToAction:hover {
  background-color: #166555;
  color: #d7c3f1;
  border-color: #166555;
}

.callToAction:focus {
  background-color: #166555;
  color: #d7c3f1;
  border-color: #166555;
}

#rapuContainer {
  padding: 20px;
}

.rapu1 {
  display: none;
  position: absolute;
  left: 300px;
  height: min(57vh, 57vw);
  top: 64vh;
  max-height: 300px;
}

@media screen and (min-width: 300px) and  (min-height: 300px){
  .heroText {
    height: 92vh;
    min-height: 600px;
    padding: 100px 0px 0px 0px;
  }
}

/* vertical thin mobile*/


/* vertical mobile*/
@media screen and (min-width: 300px) and (max-width: 450px) and (min-height: 800px) and (max-height: 900px)  {
  .rapu1 {
    left: 0px;
    top: 80vh;
    height: min(55vh, 65vw);
  }
}


/* vertical tablet*/
@media screen and (max-width: 820px) and (min-height: 1000px){
  .heroText {
    height: 92vh;
    padding: 200px 0px 0px 0px;
  }

  #year{
    width: 25vw;;
  }

  .rapu1 {
    display: block;
    left: 120px;
    top: 68vh;
  }
}

@media screen and (min-width: 820px) and (max-width: 1100px) and (min-height: 1000px){
  .heroText {
    height: 92vh;
    padding: 200px 0px 0px 0px;
  }

  #year{
    width: 25vw;;
  }

  .rapu1 {
    display: block;
    top: 64vh;
    max-height: 300px;
  }
}

/* laptops */

@media screen and (min-width: 500px) and (max-height: 1000px){

  .heroText {
    height: max-content;
  }

  #otacruise{
    width: 60vw;
    max-width: 750px;
  }

  #year{
    max-width: 300px;
  }

  .rapu1{
    display: none;
  }
}

@media screen and (min-width: 1100px) and (max-height: 1000px){
  .rapu1{
    display: block;
    left: 0vh;
    top: 50vh;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) and (min-height: 1000px){
  .rapu1{
    display: block;
    left: -10vh;
    top: 70vh;
    max-height: 370px;
  }
}

@media screen and (min-width: 1400px)and (min-height: 600px){

  .heroText {
    height: 92vh;
  }

  .date{
    padding-bottom: 12vh !important;
  }

  #otacruise{
    margin-inline: 100px;
  }

  #year{
    width: 20vw;
  }

  .rapu1 {
    display: block;
    left: -30px;
    top: 55vh;
    max-height: 350px;
  }
}
