/* Navigointipalkin tyylit */

#navigationBar {
  position: fixed;
  width: 100vw;
  font-size: 20px;
}

.navbar-nav {
  margin-left: auto;
  padding-top: 10px;
}

.nav-item.ps-5 {
  color: #d7c3f1;
  transition: color 0.3s ease-out;
}

.nav-item.ps-5:hover {
  color: #d7c3f1;
  text-shadow: 1px 1px 10px #146555 ;
}

a.navTickets {
  background-color: #ffffff;
  padding: 10px;
  border-width: 2px;
  border-radius: 10px;
  text-decoration: none;
  color: #dae0ed;
  transition-duration: 0.3s;
}

a.navTickets:hover {
  background-color: #d7c3f1;
  border-width: 2px;
  border-color: #8d98fc;
  color: #8d98fc;
  text-shadow: 0;
}


/* .nav-item.ps-5:focus {
  color: #97b2f3;
} */
