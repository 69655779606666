#info-bg {
  padding: 1em;
  padding-top: 2em;
  background-image: url('../assets/images/meritausta.jpg');
  min-height: 100vh;
}

#info-container {
  padding: 30px;
  margin-bottom: 100px;
  margin-top: 50px;
  border-radius: 20px;
  background-color: #ffffff;
}

@media screen and (min-width: 770px) {
  #info-container {
    padding: 6em;
  }
}

a {
  text-decoration: none;
}

.page-sidebar {
  padding-top: 20px;
  padding: 1em;
}

.navlink {
  color: #41b3a2;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.infonav {
  font-size: 0.6rem;
}

.navlink:hover {
  color: #146555;
}

.info-title {
  color: #146555;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: 2px;
}

.subtitle {
  padding-left: 1px;
  color: #146555;
  font-size: 1.3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  letter-spacing: 2px;
}

a.ticketLink{
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #146555;
}

a.ticketLink:hover{
  color: #d7c3f1;
  border-color: #146555;
}

.linkbtn, .a {
  color: #146555;
  margin-bottom: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #146555;
  background-color: #d7c3f1;
  transition-duration: 0.3s;
}

.linkbtn:link, .linkbtn:hover {
  background-color: #146555;
  border-color: #146555;
  color: #146555;
}